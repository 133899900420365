<template>
  <div class="FollowUp-box">
    <div class="add-but">
      <div v-for="(item, index) in intercourseList">
        <el-button
          v-if="btnP.editFollowUp"
          type="primary"
          size="mini"
          style="background:none;border-color:#2370EB;color:#2370EB;;margin-bottom:16px;margin-top:16px"
          @click="onAddCommentn(item)"
        >
          <i class="el-icon-plus" style="margin-right:10px"></i>添加评论
        </el-button>
        <div class="line one" style="cursor: pointer;" @click="onEdit(item)">
          <p @click="onEdit(item)" style="cursor: pointer;">
            <span class="yuan"></span>
            <span
              style="margin-left:0;width:90px;display:inline-block;color:#666666"
              >计划时间</span
            >
            <span style="color:#333333"> {{ item.planTime }}</span>
          </p>
          <p
            v-if="item.remindStatus == 1"
            style="width:50%;height:20px;margin-right:20px;text-align:right;color:#333333"
          >
            <i class="iconfont icon-daiban1"></i>
            已提醒
          </p>
          <p
            v-if="item.remindStatus == 0"
            style="width:50%;height:auto;margin-right:20px;text-align:right;color:#333333"
          >
            <span style="width:100%;color:#333333">
              <i class="iconfont icon-daiban1" style="color:#2370EB"></i>
              提前{{
                item.remindType == 1
                  ? "15分钟"
                  : item.remindType == 2
                  ? "30分钟"
                  : item.remindType == 3
                  ? "1小时"
                  : item.remindType == 4
                  ? "2小时"
                  : item.remindType == 5
                  ? "1天"
                  : " - -"
              }}</span
            >
          </p>
        </div>
        <div class="line">
          <span>计划标题</span>
          <span>{{ item.planTitle }}</span>
        </div>
        <div class="line">
          <span>创建人</span>
          <span>{{ item.adminName }} </span>
        </div>

        <div class="line">
          <span>计划内容</span>
          <span>{{ item.planContent }}</span>
        </div>
        <div class="describe" v-for="(remark, index) in item.customerList">
          <div>
            <span>评论</span>
            <span>{{ remark.commentContent }}</span>
          </div>
          <div style="margin-top:16px">
            <span>评论时间</span>
            <span>{{ remark.createTime }} {{ nickName }}</span>
          </div>
        </div>
        <div
          class="sign"
          @click="onSign(item)"
          v-if="item.completeStatus == 0 && btnP.editFollowUp"
        >
          <span class="not"></span>

          <span style="color:#666666">标记为完成</span>
        </div>
        <div class="sign" v-if="item.completeStatus == 1">
          <span class="already">
            <i class="el-icon-check"></i>
          </span>
          <span style="color:#2370EB">已完成</span>
        </div>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisibles"
      width="560px"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          添加评论
        </div>
      </template>
      <AddComment @close="handleClose" ref="comment" />
    </el-dialog>
    <el-dialog
      :visible.sync="dialogEdit"
      width="560px"
      :before-close="handleClose"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          修改跟进计划
        </div>
      </template>
      <EditFollow @close="handleClose" ref="editfollow" />
    </el-dialog>
    <!-- 二次确认 -->
    <el-dialog
      :visible.sync="dialog"
      width="560px"
      :before-close="handle"
      append-to-body
    >
      <template slot="title">
        <div
          style="display:flex; align-items: center;color:#000000;font-size:16px;font-weight: 500;"
        >
          标记
        </div>
      </template>
      <div class="dialog-box">
        <p>是否将该条跟进计划标记为完成？</p>
      </div>
      <div class="but">
        <el-button
          round
          type="primary"
          size="mini"
          style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
          @click="dialog = false"
          >取消</el-button
        >
        <el-button
          round
          type="primary"
          style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
          size="mini"
          @click="onSubmit"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import AddFollow from "./addFollow.vue";
import AddComment from "./addComment.vue";
import EditFollow from "./editFollow.vue";
import { planList, editStatus } from "@/api/user/follow.js";
export default {
  components: {
    AddComment,
    EditFollow,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      dialogEdit: false,
      dialog: false,
      nickName: sessionStorage.getItem("nickName"),
      intercourseList: [],
      item: "",
      btnP: "",
    };
  },

  methods: {
    // 获取跟进计划
    getFollowUp(btnP) {
      this.btnP = btnP;
      let data = {
        param: {
          customerId: this.$formDetails.state.data.id,
        },
      };
      planList(data).then((res) => {
        this.intercourseList = res.data.list;
      });
    },
    //添加跟进计划
    addFollow() {
      this.dialogVisible = true;
    },
    // 添加评论说明
    onAddCommentn(item) {
      this.dialogVisibles = true;
      let that = this;
      setTimeout(() => {
        this.$refs.comment.getPlanId(item);
      }, 50);
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisibles = false;
      this.dialogEdit = false;
      this.getFollowUp(this.btnP);
    },
    // 修改跟进计划
    onEdit(item) {
      if (this.btnP.editFollowUp) {
        this.dialogEdit = true;
        setTimeout(() => {
          this.$refs.editfollow.echoData(item);
        }, 50);
      }
    },
    // 标记完成
    onSign(item) {
      this.dialog = true;
      this.item = item;
    },
    onSubmit() {
      let data = {
        param: {
          customerId: this.$formDetails.state.data.id,
          id: this.item.id,
          completeStatus: 1,
        },
      };
      editStatus(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功");
        }
        this.handle();
      });
    },
    handle() {
      this.dialog = false;
      this.getFollowUp(this.btnP);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.FollowUp-box {
  height: 100%;
  overflow: auto;
  padding: 0px 0 0 30px;
}
.add-but {
  flex: 1;
  overflow: auto;
  height: 100%;
  .line {
    padding-bottom: 16px;
    position: relative;
    span:nth-child(1) {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      display: inline-block;
      width: 60px;
    }
    span:nth-child(2) {
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      margin-left: 34px;
    }
  }
  .one {
    display: flex;
    p:nth-child(1) {
      position: relative;
      font-size: 14px;
      color: #666666;
      .yuan {
        position: absolute;
        display: block;
        width: 8px;
        height: 8px;
        background: #2370eb;
        border: 2px solid #2370eb;
        left: -30px;
        top: 6px;
        border-radius: 50%;
      }
    }
  }
  .Circle {
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    background: #2370eb;
    border: 2px solid #2370eb;
    left: -30px;
    top: 6px;
    border-radius: 50%;
  }
  .describe {
    padding-bottom: 16px;
    span:nth-child(1) {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      display: inline-block;
      width: 60px;
    }
    span:nth-child(2) {
      font-size: 14px;
      color: #333333;
      line-height: 20px;
      margin-left: 34px;
    }
  }
  .sign {
    padding-bottom: 15px;
    position: relative;
    cursor: pointer;
    .not {
      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      border: 1px solid #666;
      border-radius: 50%;
      left: -26px;
      top: 4px;
    }
    .already {
      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      border: 1px solid #2370eb;
      color: #2370eb;
      border-radius: 50%;
      left: -26px;
      top: 4px;
      line-height: 14px;
      text-align: center;
    }
  }
}
.dialog-box {
  padding: 80px 0;
  text-align: center;
  color: #333;
  font-size: 16px;
}
.but {
  width: 100%;
  height: 48px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    width: 72px;
  }
}
</style>
